.expandable-panel__header-text > .sub {
    font-family: var(--font-bold-family);
    font-weight: var(--font-bold-weight);
}
.expandable-panel__icon {
    background: var(--color-secondary);
}
@media screen and (max-width: 767px) {
    .expandable-panel {
        background-color: var(--color-secondary);
    }
    .expandable-panel__header-text {
        color: #ffffff;
        background-color: var(--color-secondary);
    }
}
@media screen and (min-width: 768px) {
    .expandable-panel__header-text {
        color: var(--color-secondary);
    }

    .expandable-panel__header:before {
        background-color: var(--color-secondary);
    }
}
