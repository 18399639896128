.btn-group.map-slide__tab-controls:before {
    content: '';
    position: absolute;
    width: 25px;
    height: 36px;
    left: 21px;
    top: 0;
    background: var(--color-info);
    -webkit-transform: skew(-20deg);
    -ms-transform: skew(-20deg);
    transform: skew(-20deg);
    z-index: 5;
}
.btn-group.map-slide__tab-controls:after {
    content: '';
    position: absolute;
    width: 25px;
    height: 36px;
    right: -18px;
    top: 0;
    background: var(--color-info);
    -webkit-transform: skew(-20deg);
    -ms-transform: skew(-20deg);
    transform: skew(-20deg);
    z-index: 5;
}
.map-slide__tab-controls>.btn {
    padding-left: 22px;
    padding-right: 22px;
}
.map-slide__tab-controls .btn-default {
    background: #dbdbdb;
}
.map-slide__tab-controls .btn-default.active, .map-slide__tab-controls .btn-default:hover {
    background: var(--color-primary);
}