h1, .h1,
h2, .h2,
h3, .h3,
h5, .h5,
h6, .h6 {
    font-family: var(--font-heading-family);
    color: var(--color-secondary);
}

h1, .h1 {
    text-transform: uppercase;
}

h5, .h5,
h6, .h6 {
    font-family: var(--font-bold-family);
    font-weight: var(--font-bold-weight);
}

a {
    color: var(--color-secondary);
}

h1 strong, .h1 strong,
h2 strong, .h2 strong,
h3 strong, .h3 strong,
h4 strong, .h4 strong,
h5 strong, .h5 strong,
h6 strong, .h6 strong,
h1 .strong, .h1 .strong,
h2 .strong, .h2 .strong,
h3 .strong, .h3 .strong,
h4 .strong, .h4 .strong,
h5 .strong, .h5 .strong,
h6 .strong, .h6 .strong {
    font-family: var(--font-heading-family);
    font-weight: var(--font-bold-weight);
}

.text-muted {
    color: var(--color-body-text);
}