:root {
    --decorated-hr-color: var(--color-primary);
}
.decorated-hr {
    overflow: hidden;
    text-align: center;
    margin-bottom: 30px;
    color: var(--decorated-hr-color);
    /* use own composition layer because of strange chrome rendering problems
       see https://elements.attask-ondemand.com/task/view?ID=55f157700019ebdf9609f28c14543612 */
    transform: translateZ(0);
}

.decorated-hr__icon {
    line-height: 1.27;
    position: relative;
    /*font-size: 60px;*/
    font-size: 58px;
    padding: 0 15px;
}

.decorated-hr__left,
.decorated-hr__right {
    display: block;
    position: absolute;
    top: 50%;
    left: 100%;
    width: 1000px;
    height: 0;
    border-top: 2px solid;
    margin-top: -1px;
    color: inherit;
}
.decorated-hr__right {
    left: auto;
    right: 100%;
}

