ul.list-default>li:before,
.wysiwyg ul>li:before {
    content: "";
    position: relative;
    top:-2px;
    transform: rotate(90deg);
    display: inline-block;
    margin:0 5px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 4px 7px 4px;
    border-color: transparent transparent #000 transparent;
}

.wysiwyg h1, .wysiwyg h2, .wysiwyg h3, .wysiwyg h4, .wysiwyg h5, .wysiwyg h6, .wysiwyg a {
    color: var(--color-secondary);
}