.footer-wrapper {
    background: var(--color-secondary);
    color: #ffffff;
}
.footer-wrapper h3, .footer-wrapper a, .footer-wrapper a.text-primary,
.footer-wrapper a:hover, .footer-wrapper a.text-primary:hover{
    color: #ffffff;
}
.footer__copyright {
    padding-top: 15px;
}
.footer-sprite {
    background-image: url(/static/img/dachstein/sprite/footer-sprite.png);
    background-repeat: no-repeat;
    display: block;
}

.round-helper {
    display: none;
}
.footer__copyright {
    min-height: 0;
}