.btn-primary {
    background: var(--color-secondary);
}
.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active,
.btn-primary.active {
    background: color(var(--color-primary) b(+10%));
}

.footer .btn-primary {
    background: #fff;
    color: var(--color-secondary);
}

.footer a.btn-primary:hover {
    color: var(--color-secondary);
}

.footer .btn-primary .btn__icon {
    color: #fff;
}