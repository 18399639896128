:root {
    --img-poi-bg-color: rgba(0,0,0,.5);
    --img-poi-bg-color-fallback: #000;
    --img-poi-bg-active: var(--color-primary);
}
.img-poi {
    display: block;
    position: absolute;
    color: var(--color-primary);
    width: 0;
    height: 33px;
    border-style: solid;
    border-width: 0 20px 33px 20px;
    border-color: transparent transparent rgba(0, 81, 158, 0.5) transparent;
}
.img-poi:after {
    content: '';
    position: absolute;
    left: -5px;
    top: 16px;
    background: rgba(255,237,0,.8);
    width: 10px;
    height: 10px;
    border-radius: 5px;
}
.img-poi__content {
    display: block;
    position: absolute;
    top: 0;
    right: 100%;
    background: #000;
    background: rgba(255, 237, 0, 0.8);
    white-space: nowrap;
    padding: 3px 15px 2px;
    transform: skew(-31deg);
    -webkit-transform: skew(-31deg);
    -ms-transform: skew(-31deg);
    margin-right: 13px;
    z-index: 1;
    height: 33px;
}
.img-slide__content {
    z-index: 0;
}
.img-slide__content--full-height {
    z-index: 1;
}
.img-poi__content-text {
    display: block;
    transform: skew(31deg);
    -webkit-transform: skew(31deg);
    -ms-transform: skew(31deg);
}
/*hover*/
a.img-poi:hover {

}
a.img-poi:hover>.img-poi__content,
a.img-poi:hover>.img-poi__content:before,
a.img-poi:hover>.img-poi__content:after,
a.img-poi:focus>.img-poi__content,
a.img-poi:focus>.img-poi__content:before,
a.img-poi:focus>.img-poi__content:after{
    background: var(--img-poi-bg-active);
    color: var(--color-primary-comp);
}
a.img-poi:hover, a.img-poi:focus {
    border-color: transparent transparent var(--img-poi-bg-active);
}
.img-poi:focus { outline: none; }